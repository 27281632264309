import React, { useEffect } from 'react';
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import Back from '../components/back/back';
import Glide from "@glidejs/glide"
import ToTop from "../components/to-top/to-top";

const sliderConfig= {
  gap: 20,
  perView: 1,
  startAt: 0,
  type: "slider"
};
const GovFinancialApp = () => {
  useEffect(() => {
    const sliders = document.querySelectorAll('.glide');

    sliders.forEach(item => {
        new Glide(item, sliderConfig).mount()
    })
  }, [])

  return (
    <Layout>
      <div className="details case-study gov-rd">
        <SEO title=".Gov Website Redesign" />

        <div className="fixed-nav">
          <a className="home-link fixed-link" href="/">
            <img className="arrow-left" alt="arrow-left" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
            <span>home</span>
          </a>
          <a className="next-link fixed-link" href="/react-storybook">
            <span>next</span>
            <img className="arrow-right" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
          </a>
        </div>
        <ToTop></ToTop>
        <section className="wrapper-container">
          <div className="row">
            <div className="col">
              <div className="vert-center">
              <h3>Promoting design thinking in a government contract.</h3>
              <p>
              Because of the requirements-driven nature of government contracting, users and their experiences are often secondary considerations. 
              </p>
              <p>
              My contributions were part of a larger internal initiative to promote design thinking.
              </p>
              </div>
            </div>
            <div className="col with-image">
              <img alt="USA Flag" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739208942/flag-vert_bsxcp9.png"></img>
            </div>
          </div>

        </section>
        <section className="wrapper-container image-container">
          {/* Hi-Fi Screens */}
          <div className='glide mb-20 mt-20'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1583173090/fin-7_demnkx.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1583173090/fin-7_demnkx.png" ></img></a>
                  </li>
                  <li className='glide__slide slider'>
                    <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1583172880/fin-8_xumpqq.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1583172880/fin-8_xumpqq.png" ></img></a>
                  </li>
                </ul>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396471/chevron-left_io2o5z.png"></img></button>
                <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img className="chevron" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1728396472/chevron-right_ejgxv3.png"></img></button>
              </div>
            </div>
        </section>

        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                Initial deadlines and recommendations
                </h3>
                <p>
                The initial task for a .gov redesign included delivery of wireframes pertaining to a landing page, reporting, and an administrative dashboard.
                </p>
                <p>
                Wireframing was based on requirements driven by the project management office. Upon completion of the deliverables, a deeper dive into the underlying data and user experience was performed.
                </p>
                
              </div>
            </div>
          </div>
        </section> 
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                Research and requirements
                </h3>
                <p>
                Customer support queries, weighted priority lists from stakeholders, and user and stakeholder interviews were utilized.
                </p>
                <p>
                Tablet and non-fullscreen resolutions, for both RWD and future kiosk considerations, were prioritized.
                </p>
              </div>
            </div>
          </div>
        </section> 
        <section className="wrapper-container image-container">
          {/* Wireframes */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1739109687/gov-wf-cropped_j1u7rs.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739109687/gov-wf-cropped_j1u7rs.png" ></img></a>
        </section>

        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                Increased fidelity and selling design thinking
                </h3>
                <p>
                After continuing a heuristic analysis and further review of research over requirements, the initiative became one of promoting a more user-centric redesign.  
                </p>
                <p>
                A resource in the form of a UX Architect was allocated in what would be a team effort to structure a design thinking sales deck and initial redesign project plan.
                </p>
                <p>
                Fidelity was increased due to changes in audience and the need to be part of the sales process.
                </p>
              </div>
            </div>
          </div>
        </section> 
        <div className="project-bottom">
          <Link to="/react-storybook" title="To pattern library">
            <span>a pattern library</span>
            <img className="arrow-right-long" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/arrow-right-long-light_1_qchclz.png"></img>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default GovFinancialApp;